hr{
    height:50px;
}
.section-header 
{
    background-color: #222;
    color:#f2f2f2;
    width:100%;
    padding: 0;
    margin-bottom:25px;
    padding: 25px;
}
.card-section
{
    
}

@media screen and (max-width:780px)
{
.card-section{
       flex-direction: column;
       justify-content: space-between;
       align-items:center;
    }
}