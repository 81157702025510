.c-card{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    color:#222;
    margin: 1rem;
    max-width: 25rem;
}
.c-card:hover{
    color:#222;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

@media screen and (max-width:780px)
{
.c-card{
       max-width: 20rem;
    }
}

.c-header{
    color:#f2f2f2;
    background-color: #222;
    margin: 0;
    font-size: 2em;
    padding: 10px;    
    letter-spacing: 2px;
}
.c-genre
{
    color:#f2f2f2;
    background-color: #222;
    text-align: center;
    padding:10px;
}
.c-image{
    background-color: #222;
    padding: 0;
    margin: 0;
    
}

