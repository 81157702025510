.options-ct
{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color:#d2d2d2;
    padding: 0;
    margin : 5rem 0 0  0;
}

.option{
    text-align: left!important;
    background-color: #222;
    color:#d2d2d2;
    Margin:5px;
    max-width:712px;
    width:100%;
    border-radius: 5px;
    border:none;
    box-shadow: 1px 1px 5px black;
    padding:8px;
    padding-left: 15px;
    animation:fadeIn 2s;
}

.option:nth-child(1)
{
    animation:fadeIn .9s;
}

.option:nth-child(2)
{
    animation:fadeIn 1.2s;
}

.option:nth-child(3)
{
    animation:fadeIn 1.5s;
}

@keyframes fadeIn{
    0%{opacity:0;}
    70%{opacity: 0;}
    100%{opacity:1;}
}

.option-icon{
   opacity: 0;
}

.option:hover {
color:#ddb500;
border:none;
box-shadow: 1px 1px 8px black;
  
}

.option:hover .option-icon{
    opacity:1
}
.option:active
{
    box-shadow: 1px 1px 2px black;
}

