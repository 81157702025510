.nbar
{
    padding:0 8rem!important ;
}

nav
{ 
    padding: 0 10px;
    margin:0;
    background-color: #222;
}

.navbar-nav
{
    padding:0;
    margin:0;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    width:100%;
    align-items: center;
    color:#f2f2f2;
}

ul
{
 list-style: none;
}

.nav-link {
    padding : 5px;
    font-size: 1em;
    letter-spacing: 1.2px;
    text-transform: uppercase;

}

.brand p:nth-child(2){
    text-transform: capitalize;
}
.brand
{
    text-align: left;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-size: 1em;
    align-self:flex-start;
    margin-right: auto;
}

.brand p{
    margin:0;
}

.nav-link
{
    text-decoration: none;
    color: #f2f2f2 !important;
    border: .8px solid transparent;
}

.nav-link:hover{
    border-bottom: .8px solid #f2f2f2;
}