@font-face {
    font-family: "skranji";
    src: local("skranjiBold"),
        url("../fonts/skranji-bold.ttf") format("truetype");
    font-weight: bold;
}

/* Hero container */
.pr-hero {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left : 20px;
    margin-right: 20px;
}

/* Project logo */
.pr-banner {
    background-color: #222;
    width: 100%;
    padding:5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Project basic info ct */
.pr-hero-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #222;
    
    
}
@media screen and (max-width:780px)
{
    .pr-hero-info{
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
        padding-left: 20px;
    }
    .pr-hero-details {
        padding-right: 20px;
        padding-left: 20px;
        
    }

}

.pr-hero-details {
    height: 100%;
    margin: 5rem 0;
}

.pr-hero-info-video {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 2rem;

}

.pr-hero-info-video iframe {
    width: 713px;
    min-height: 352px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pr-hero-info-text {
    font-weight: 500;
    font-size: 1em;
    color: #f2f2f2;
}

.pr-nav {
    width: 100%;
    height: 100%;
    background-color: #f2f2f2;
    color: #222;
}

.pr-nav nav {
    background-color: #f2f2f2;
    text-align: center;
    
    width: 100%;
    color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 5px #222;
}


.pr-nav-a {
    color: #222;
}



.pr-hero-nav {
    width: 100%;
    display: flex;
    background-color: #222;
    padding: 25px;
}






.project-ct {
    /* height:200vh; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
}

.project-h1 {
    margin: 0 auto;
    vertical-align: center;
    font-family: "Skranji";
    letter-spacing: 4px;
}

.project-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 100%;
    width: 100%;

}

.project-base {
    padding: 25px;
}

.project-logo {
    margin: 25px;
}

.project-about-content {
    position: relative;
    z-index: 100;
}

.project-info {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60vh;
}

.project-about-content {
    margin: 0 auto;
    width: 60%
}

.project-about-bg {

    width: 100%;
    height: inherit;
    position: absolute;
    margin: 0;
    /* transform: rotate(45deg); */
    background-color: #212121;
    z-index: -100;
}

.project-bg {
    width: 100%;
    height: 100%;
}

.project-base-kp {
    list-style-type: disc;
    color: #f2f2f2;
}

.keypoint {
    text-transform: none;
    font-size: 1em;

    font-weight: 400;
    padding: 2px;
}

.project-about {
    width: 100%;
    height: 100%;

    color: #f2f2f2;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.project-about-footer {
    background-color: #222;
    height: 100%;
    width: 100%;
}