.section-text{
    flex: 1 1 100%;
    margin-top:50px;
    
}
.section-text p{
 
}

.section-img {
    text-align: center;
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
}
.section-img img{
    width: 524px;
    height: 265px;
    margin:0 auto;
}
.pr-details {
    display: flex;
    flex-direction: column;
}

.pr-details-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 25px;
}

.pr-details-section:nth-child(odd) {
    background-color: #222;
    color: white;
}

.pr-details-section:nth-child(even) .section-text {
    order: 1;
}

@media screen and (max-width:780px)
{
    .pr-details-section{
        flex-direction: column;
        justify-content: space-around;
        height:100%;
    }
    .section-text{
        flex:0 0 100%;
        height: 100%;
    }
    .section-img img{
        width: 370px;
        height:200px;
    }
    .section-img{
        flex: 0  0 100%;
    }
    .pr-details-section:nth-child(even) .section-text {
        order:0;
    }
}