.resume-ct {
    margin: 0 auto;
    padding: 0 10rem 0 10rem;
    height: 100%;
    display:flex;
    flex-direction: column;
    
}

.r-content {
    display: flex;
}

.loc-col {
    color: rgb(201, 0, 0);
}

.brief-contact-list li {
    padding: 3px;
}

.brief-contact-list li i {
    padding-right: .5rem;
}

.brief {
    font-size: .9em;
    width:80%;
    margin:auto 0;
    background-color: #222;
    color: #f2f2f2;
    border-radius: 12px;
    padding: 1rem;
}

.exp-tab
{
    color: #222!important;;
}

.brief-contact {
    color: #f2f2f2;
}

.brief-contact-list {
    padding: 0;
}
.r-exp
{
    display:flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width:780px)
{
    .resume-ct
    {
        padding:0;
    }
    .r-content{
        flex-direction: column;
    }
    .brief
    {
        width:100%;
    }
    .r-exp{
        flex-direction: column;
    }
   h4{
       text-align: center;
   }
}