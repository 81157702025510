.home-ct 
{
    
    width:100%;
    height:90vh;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.banner
{
    height: 70vh;
    width:100%;
    display:flex;
    flex-direction: column;
}
.img-placeholder
{
    color:#f2f2f2;
    background-color: rgb(29, 29, 29);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.banner-txt-ct
{
    font-size: 3em;
    text-align: center;
    margin:0 auto;
    
}

.img-rights
{
    position:absolute;
    bottom:8px;
    right: 16px;

}